import {
  ArrowLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  PaperAirplaneIcon
} from "@heroicons/react/outline";
import { Link } from "gatsby";
import * as React from "react";
import { useState, useEffect } from "react";
import { getGuideById } from "app/services/aerocharter";
import eventBus from "app/services/eventBus";
import { useStore } from "app/store/store";
import Layout from "components/global/layout";
import Navbar from "components/global/navbar";
import PageTitle from "components/global/page-title";
import { generateBarcode } from "components/helpers";

const PlaceholderList = () => {
  return (
    <div className="bg-gray-200 bg-opacity-20 h-56 rounded-md my-6 animate-pulse duration-75 flex-col space-y-4">
      {[...Array(3)].map((_, index) => (
        <div
          key={index}
          className="bg-gray-300 bg-opacity-20 h-16 rounded-md animate-pulse duration-300"
        ></div>
      ))}
    </div>
  );
};

const PulsingAirplaneIcon = () => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-4 z-20 bg-gray-100 rounded-full ">
      <div className="relative w-6 h-6 rotate-180">
        <PaperAirplaneIcon className="stroke-primary-light-green w-6 h-6 absolute animate-ping" />
        <PaperAirplaneIcon className="stroke-primary-light-green w-6 h-6" />
      </div>
    </div>
  );
};

const BidirectionArrowIcon = () => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-10 z-20 bg-gray-100 rounded-full ">
      <div className="relative">
        <ChevronUpIcon
          className="h-6 w-6 text-gray-200 absolute -translate-y-5"
          style={{ backgroundColor: "#f6f7f8" }}
        />
        <ChevronDownIcon
          className="h-6 w-6 text-gray-200 absolute -translate-y-1"
          style={{ backgroundColor: "#f6f7f8" }}
        />
      </div>
    </div>
  );
};

const GreenDotIcon = () => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-4 -mr-px -translate-x-[1.25px] h-6 w-6 bg-primary-light-green rounded-full z-20">
      <div className="h-3 w-3 bg-white rounded-full translate-x-1/2 translate-y-1/2"></div>
    </div>
  );
};

const RedDotIcon = () => {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 right-4 -mr-px -translate-x-[1.25px] h-6 w-6 bg-primary-light-red rounded-full z-20">
      <div className="h-3 w-3 bg-white rounded-full translate-x-1/2 translate-y-1/2"></div>
    </div>
  );
};

const SeparationLine = () => (
  <span
    className="absolute top-12 right-7 -mr-px h-full w-0.5 bg-gray-200 z-10"
    aria-hidden="true"
  ></span>
);

const Rastreo = ({ location }) => {
  const [globalState] = useStore();
  const [guide, setGuide] = useState(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    // load guide from aerocharter service

    if (!globalState.authToken) return;
    eventBus.publish("NOTIFICATION", {
      type: "info",
      title: "Cargando guía",
      message: "Espere un momento por favor..."
    });

    let params = Object.fromEntries(
      location.search
        .split("?")[1]
        .split("&")
        .map((item) => item.split("="))
    );
    console.log("GUIA", params.guia);

    if (params.guia) {
      getGuideById(globalState.authToken, params.guia)
        .then((res) => {
          const _guide = res.payload;
          setGuide(_guide);
          setSteps(_guide.steps);

          if (!res.success) {
            eventBus.publish("NOTIFICATION", {
              type: "error",
              title: "Error al cargar guía",
              message:
                "No se ha podido cargar la guía, por favor inténtalo más tarde"
            });
            return;
          }
          eventBus.publish("NOTIFICATION", {
            type: "success",
            title: "Guía cargada",
            message: "La guía se ha cargado correctamente"
          });

          setTimeout(() => {
            eventBus.publish("CLEAN_NOTIFICATIONS");
          }, 500);
        })
        .catch((err) => {
          console.log("error", err);
          eventBus.publish("NOTIFICATION", {
            type: "error",
            title: "Error al cargar guía",
            message:
              "No se ha podido cargar la guía, por favor inténtalo más tarde"
          });
        });
    }
  }, [setGuide, globalState.authToken]);

  return (
    <>
      <Navbar />
      <Layout>
        <PageTitle>Rastreo</PageTitle>
        {/* create a placeholder while data loads */}
        {!guide && <PlaceholderList />}
        {guide && (
          <div className="my-6 space-y-3">
            <div className="text-center uppercase font-display text-lg">
              <span className="font-bold text-primary-light-green">
                id de rastreo:
              </span>{" "}
              {guide._id}
            </div>
            <div className="md:w-9/12 md:mx-auto md:my-20 md:rounded-2xl md:shadow-md md:pt-12 overflow-hidden">
              <div className="md:max-w-md md:mx-auto md:pb-6">
                <ul className="-mb-8">
                  {steps.map((node, index) => {
                    return (
                      <li key={index}>
                        <div className="relative pb-4">
                          <div className="bg-gray-300 rounded-sm bg-opacity-20 relative flex flex-col px-5 py-4 uppercase">
                            <div className="flex justify-between mr-6">
                              <div>{node.date}</div>
                            </div>
                            <div className="mt-2 font-display font-bold text-sm text-primary-gray">
                              {node.description}
                            </div>
                            <div className="">{node.location}</div>
                            {
                              /* error message */
                              node.error && (
                                <div className="mt-2 text-orange-400">
                                  <ExclamationCircleIcon className="inline-block mr-2 w-4 h-4 stroke-orange-400" />
                                  {node.error}
                                </div>
                              )
                            }
                            {
                              // icon for step
                              node.error ? (
                                <RedDotIcon />
                              ) : node.status === "DONE" ? (
                                <GreenDotIcon />
                              ) : node.status === "PENDING" ? (
                                <BidirectionArrowIcon />
                              ) : (
                                node.status === "CURRENT" && (
                                  <PulsingAirplaneIcon />
                                )
                              )
                            }
                          </div>
                          {
                            /* draw line */
                            index !== steps.length - 1 && <SeparationLine />
                          }
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="flex items-center justify-center px-8 pt-8">
                  <Link
                    to="/pago-completado"
                    className="flex bg-primary-light-red btn-shape text-white w-full items-center justify-center "
                  >
                    <ArrowLeftIcon className="h-4 w-4 mr-2" />
                    <span>Regresar</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Rastreo;
